* {
  box-sizing: border-box;
}


.text-error {
  padding-left: 10px;
  color: red;
  font-size: 11px;
}

.slick-dots {
  position: relative;
  top: -85px;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 40px;
  line-height: 1;
  opacity: .75;
  color: #f40c43;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next {
  right: 40px;
  z-index: 99;
}

.slick-prev {
  left: 20px;
  z-index: 99;
}

.slick-slider {
  overflow: hidden;
  height: 100%;
}

.slick-slide>div {
  width: 100%;
  height: 100%;
  max-height: 600px;
}

.slick-slide>div>img {
  width: 100%;
}

/* property details css */

 

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}  
 

.MuiPickersDay-root.Mui-disabled {
  background: #eaeaea;
}